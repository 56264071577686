<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
      />
      <v-spacer class="col-3" />
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" class="col-2" min-width="150">
            New project
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.poNumber"
                    label="Purchase order "
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    id="userSelect"
                    :items="users"
                    item-text="name"
                    v-model="editedItem.user"
                    :rules="[rules.required]"
                    label="User"
                    name="user"
                    return-object
                    required
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-combobox
                    v-model="editedItem.customer"
                    :loading="loading_customers"
                    :items="searchResultCustomers"
                    :search-input.sync="search_customers"
                    clearable
                    auto-select-first
                    label="Customer"
                    :rules="[rules.required]"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="cancel" text @click="close"> Cancel </v-btn>
            <v-btn color="primary" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="projects"
      :search="search"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    errors: [],
    dialog: false,
    search: null,
    select: null,
    search_customers: null,
    loading_customers: false,
    searchResultCustomers: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Purchase order",
        value: "poNumber",
      },
      {
        text: "Customer",
        value: "customer",
      },
      {
        text: "User",
        value: "user.name",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "right",
      },
    ],
    editedItem: {
      id: "",
      customer: "",
      user: "",
      poNumber: "",
    },
    editedIndex: -1,
    defaultItem: {
      id: "",
      customer: "",
      user: "",
      poNumber: "",
    },
  }),
  computed: {
    ...mapGetters({
      projects: "projects/projects",
      users: "users/users",
    }),
    // TODO: customers
    customers() {
      return ["foo", "bar"]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New project" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    search_customers(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    querySelections(v) {

      this.loading_customers = true;

      // list of found projects with matching customer
      let projects = []
      projects = this.projects.filter((e) => {
        return (
          (e.customer || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        )
      })
      // create list of customer names
      let customers = []
      projects.forEach(element => {
        // Remove duplicate customer names that are found
        if(customers.indexOf(element.customer) === -1) customers.push(element.customer)
      });
      this.searchResultCustomers = customers
      this.loading_customers = false;

    },
    editItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store.dispatch("projects/deleteProject", item).catch((e) => {
          this.errors.push(e);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store

          .dispatch("projects/editProject", {
            item: this.editedItem,
            index: this.editedIndex,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("projects/addProject", this.editedItem)
          .catch((e) => {
            console.log(e);
          });
      }
      this.close();
    },
  },
};
</script>
