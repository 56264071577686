<template>
  <Projects />
</template>

<script>
// @ is an alias to /src
import Projects from "@/components/admin/Projects.vue";

export default {
  name: "ProjectsView",
  components: {
    Projects,
  },
};
</script>
